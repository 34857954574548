<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/PageHeader.vue";
import appConfig from "../../../app.config";

export default {
  page: {
    title: "Cookie",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  created: function () {
  },
  methods: {
  },
  mounted() {
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var url = urlParams.get("redirectUrl");
    if(url && url.startsWith('/') && url !== '/login')
      this.$router.push(url);
    else
      this.$router.push('/');
  },
  computed: {
  }
};
</script>
    
<template>
  <Layout>
    <PageHeader :title="title" :description="description" />
  </Layout>
</template>